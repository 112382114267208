<template>
  <div>
    <p>
      <strong>What?</strong>
    </p>
    <p>
      nah.
    </p>
  </div>
</template>

<script>
</script>

<style>
</style>
