<template>
  <div id="app">
    <OutsideNavbar/>
    <section class="section">
      <div class="container">
        <OutsideSplash msg="OUTSIDE BROADCAST NETWORK"/>
          <RouterView />
      </div>
    </section>
    <OutsideFooter/>
  </div>
</template>

<script>
import OutsideSplash from './components/OutsideSplash.vue'
import OutsideNavbar from './components/OutsideNavbar.vue'
import OutsideFooter from './components/OutsideFooter.vue'

export default {
  name: 'App',
  components: {
    OutsideSplash,
    OutsideNavbar,
    OutsideFooter
  }
}
</script>

<style>
@import "https://cdn.jsdelivr.net/npm/bulma@0.9.4/css/bulma.min.css";
@import url('https://fonts.googleapis.com/css2?family=Exo+2:wght@300;900&display=swap');

#app {
  font-family: Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: left;
  color: #2c3e50;
  font-weight: normal;
  font-size: 2.5vw; 
}

p {
  margin-top: 2vw;
}

h1 {
  font-family: 'EXO 2', sans-serif;
  font-size: 10vw;
  font-weight: 900;
}

h3 {
  font-size: 3vw;
  margin: 3vw 3vw 0;
  font-weight: 300;
}

.navbar-item {
  font-size: 1.5vw; 
}

</style>
