<template>
  <div>
    <p>
      <strong>Celebrating 25 years of tears!</strong>
    </p>
    <p>
      Outside Broadcast Network was founded way back in December of 1996.  In the ensuing 25 years, we have accomplished absolutely nothing- and we didn't even look 
      all that great doing it. 
    </p>
    <p>
      Special shout out to all the fine folks at Mariah Media and Winston & Strawn LLP. We couldn't have survived this long without all of your hard work. Anybody know what Brian Heidelberger is up to?
    </p>
  </div>
</template>

<script setup lang="ts">
</script>

<style>
</style>
