<template>
  <div class="splash">
    <h1>OUTSIDE<br/>BROADCAST<br/>NETWORK<br/></h1>
  </div>
</template>

<script>
export default {
  name: 'OutsideSplash',
  props: {
    msg: String
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>

</style>
