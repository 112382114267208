<template>
  <nav class="navbar is-fixed-top is-dark" role="navigation" aria-label="main navigation">
    <div class="navbar-brand">
      <a class="navbar-item" href="https://outside.net">

      OBN<strong>25</strong>
      </a>
    </div>
  </nav>
</template>

<script>
export default {
  name: 'OutsideNavbar',
  props: {
    
  }
}
</script>

<style scoped>

</style>
