<template>
<footer class="footer">
  <div class="content has-text-centered">
    <p>
       &copy; {{ new Date().getFullYear() }} <strong>OBN</strong>. Built without ❤️ in <strong>Los Angeles, California</strong>.
    </p>
    <p class="jerk">
      You can't afford this domain, so let's assume it's just not for sale.
    </p>
  </div>
</footer>
</template>

<script>
export default {
  name: 'OutsideFooter',
  props: {
    
  }
}
</script>

<style scoped>
.footer {
  font-size: 1.2vw; 
}

.jerk {
  font-size: .9vw;
}
</style>
